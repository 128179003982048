import React, {Component} from "react";

import checkCircle from "./check-circle.svg";
import {translate, interactionHandler} from "./helpers";
import { getStaticImgUrl } from './utils';


class SingleInputElement extends Component {

    _setValueFromEvent(e, blockAdvance) {
        if (this.props.disabled) return;
        let value = !isNaN(parseFloat(this.inputEl.value)) ? parseInt(this.inputEl.value, 10) : this.inputEl.value;
        this.props.setValue({value, intValue: this.props.option.intValue}, blockAdvance);
    }
}

class MultipleInputElement extends Component {
    _setValueFromEvent(e, blockAdvance) {
        if (this.props.disabled) return;

        let value = !isNaN(parseFloat(this.inputEl.value)) ? parseInt(this.inputEl.value, 10) : this.inputEl.value;

        const setValue = e.type === "change" ? e.target.checked : !this.inputEl.checked;
        if (setValue) {
            this.props.setValue({value, intValue: this.props.option.intValue}, blockAdvance);
        } else {
            this.props.unsetValue({value, intValue: this.props.option.intValue}, blockAdvance);
        }
    }
}

export class ImageQuestionElement extends Component {
    render() {
        const option = this.props.option;
        let captionElement;
        if (option.caption && this.props.questionType !== 'factors' && Object.keys(option.caption).length !== 0) {
            captionElement = <figcaption>{translate(option.caption, this.props.lang)}</figcaption>;
        }

        let check = <img className={'check-circle ' + (this.props.checked ? '' : 'opacity-0')} src={checkCircle} alt="Check"/>;
        const image = this.props.isDark ? '/Button-X-25.png' : '/Button-X-75.png';
        const imgUrl = option.imgUrl
            ? getStaticImgUrl(option.imgUrl)
            : option.intValue === -2 && process.env.PUBLIC_URL + image; // ToDo: check this

        return (
            <label className="option-wrap" onClick={interactionHandler.bind(this)(this.props.setValue)}
                onTouchStart={interactionHandler.bind(this)(this.props.setValue)}>
                    <input ref="inputEl" type={this.props.type} name={this.props.name}
                        className={this.props.extraClass}
                        value={option.id} disabled={this.props.disabled} checked={!!this.props.checked}
                        onChange={interactionHandler.bind(this)((e) => this.props.setValue(e, true))}
                        onClick={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()}
                        aria-label={option.imageLabel ? translate(option.imageLabel, this.props.lang) : option.caption ? translate(option.caption, this.props.lang) : 'Image'}
                    />
                <figure>
                    <img src={imgUrl ? imgUrl : ""} alt={option.caption ? translate(option.caption, this.props.lang) : ''} />
                    {captionElement}
                    {check}
                </figure>
            </label>
        );
    }
}

export class RadioImage extends SingleInputElement {
    render() {
        return (
            <ImageQuestionElement
                type="radio"
                questionType={this.props.questionType}
                name={this.props.name}
                option={this.props.option}
                lang={this.props.lang}
                setValue={this._setValueFromEvent.bind(this)}
                disabled={false}
                extraClass={this.props.extraClass}
                checked={!!this.props.checked}
                ref={(ref) => this.inputEl = ref ? ref.refs.inputEl : null}
                isDark={this.props.isDark}
            />);
    }
}

export class CheckboxImage extends MultipleInputElement {
    render() {
        return (
            <ImageQuestionElement
                type="checkbox"
                name={this.props.name}
                option={this.props.option}
                lang={this.props.lang}
                setValue={this._setValueFromEvent.bind(this)}
                disabled={this.props.disabled}
                extraClass={this.props.extraClass}
                checked={!!this.props.checked}
                ref={(ref) => this.inputEl = ref ? ref.refs.inputEl : null}
                isDark={this.props.isDark}
            />);
    }
}

class Bar extends Component {
    render() {
        return (
            <label className={this.props.option.cssClass || ''}>
                <input ref="inputEl" type={this.props.type} name={this.props.name}
                       value={this.props.option.id} disabled={this.props.disabled}
                       className={this.props.extraClass} checked={!!this.props.checked}
                       onChange={interactionHandler.bind(this)(e => this.props.setValue(e, true))}
                       />
                <div className={`btn ${!!this.props.checked ? 'checked' : ''} ${this.props.extraClass}`}
                     onClick={interactionHandler.bind(this)(this.props.setValue)}
                     onTouchStart={interactionHandler.bind(this)(this.props.setValue)}>
                    {this.props.option.caption
                        ? translate(this.props.option.caption, this.props.lang)
                        : this.props.option.intValue}
                </div>
            </label>)
    }
}

export class CheckboxBar extends MultipleInputElement {
    render() {
        return <Bar type="checkbox" name={this.props.name} option={this.props.option} lang={this.props.lang}
                    setValue={this._setValueFromEvent.bind(this)} disabled={this.props.disabled}
                    extraClass={this.props.extraClass} checked={!!this.props.checked}
                    ref={(ref) => this.inputEl = ref ? ref.refs.inputEl : null}/>
    }
}

export class RadioBar extends SingleInputElement {
    render() {
        return <Bar type="radio" name={this.props.name} option={this.props.option} disabled={false}
                    setValue={this._setValueFromEvent.bind(this)} lang={this.props.lang}
                    extraClass={this.props.extraClass} checked={!!this.props.checked}
                    ref={(ref) => this.inputEl = ref ? ref.refs.inputEl : null}/>
    }
}

export class NpsElement extends SingleInputElement {
    render() {
        const i = this.props.i;
        let classes = 'option-wrap' + (i === 4 ? ' extra-left-margin-sm' : i === 6 ? ' extra-right-margin-sm' : '');
        let minText, maxText;
        if (this.props.isFirst)
            minText = <span className="text-min">{translate(this.props.option.caption, this.props.lang)}</span>;
        if (this.props.isLast)
            maxText = <span className="text-max">{translate(this.props.option.caption, this.props.lang)}</span>;

        return (
            <label className={classes} 
                onClick={interactionHandler.bind(this)(e => this._setValueFromEvent.bind(this)(e, false))}
                onTouchStart={interactionHandler.bind(this)(e => this._setValueFromEvent.bind(this)(e, false))}
            >
                <input ref={(ref) => this.inputEl = ref} type="radio" className={this.props.extraClass}
                    name={this.props.name} value={this.props.option.id} checked={!!this.props.checked}
                    onChange={interactionHandler.bind(this)(e => this._setValueFromEvent.bind(this)(e, true))}
                    onClick={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()}
                />
                <span className="border-circle">{i}</span>
                {minText}
                {maxText}
            </label>
        )
    }
}
