import React from "react";

import leftArrowBlack from "./css/left-black-bold.svg";
import leftArrowWhite from "./css/left-white-bold.svg";
import rightArrowBlack from "./css/right-black-bold.svg";
import rightArrowWhite from "./css/right-white-bold.svg";
import Logo from './components/Logo';
import { SKP_ID, SKP_INTVAL } from './constants';
import { interactionHandler, isFactors, translate } from "./helpers";

export default class FooterBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {surveyData: props.startingSurveyData || {}, visible: true};
    }

    // <nav id="gr-bottom-nav" className={this.state.position}>
    render() {
        let className = 'hidden';
        if (this._isNotHidden()) {
            className = this._isFullBar() ? 'full-bar' :''
        }

        return (
            <nav id="gr-bottom-nav" className={className}>
                {this._getBackButton()}
                {this._shouldShowLanguages() ? this._getLangButton() : ''}
                {this.props.logo && <Logo baseTheme={this.props.baseTheme} />}
                <div className="pull-right" style={{display: "flex"}}>
                    {this._getInfoButton()}
                    {this._getForwardButton()}
                </div>
            </nav>
        )
    }

    _isNotHidden() {
        const canShowFwdButton = this._canShowForwardBtn();
        return this.state.visible && (this.props.showBackBtn || canShowFwdButton.canProceed || canShowFwdButton.isSkip
                || this.props.languages.length > 1 || this.props.showInfoBtn);
    }
    _isFullBar() {
        const canShowFwdButton = this._canShowForwardBtn();
        return (!!this.props.showBackBtn
            + !!this.props.showInfoBtn
            + !!this._shouldShowLanguages()
            + (canShowFwdButton.canProceed || canShowFwdButton.isSkip)) > 2
    }

    _shouldShowLanguages() {
        return this.props.languages && this.props.languages.length > 1;
    }
    _getLangButton() {
        const langObj = this._getCurrentLangObject();
        return (
            <button id="lang-choice-btn" className={`flag-icon ${langObj.flagCss}`} aria-label={langObj.name}
                    onClick={interactionHandler.bind(this)(this.props.toggleLanguagePrompt)}
                    onTouchStart={interactionHandler.bind(this)(this.props.toggleLanguagePrompt)}>
            </button>
        );
    }

    _getCurrentLangObject() {
        if (this.props.languages) {
            return this.props.languages.find((langObj) => langObj.code === this.props.lang)
        }
    }

    _getForwardButton() {
        const { canProceed, isSkip } = this._canShowForwardBtn();
        const className = (isSkip || !canProceed ? "skip" : "proceed");
        const buttonTextObj = isSkip ? this.props.skipText : this.props.proceedText;
        const buttonText = translate(buttonTextObj, this.props.lang);

        // NOTE: No alt text for the image when there already is a button text. But there should be an empty alt attribut on the button
        if (!this.props.questionPrompt) {
            return (
                <button id="next-page" className={className} disabled={!canProceed}
                        onClick={interactionHandler.bind(this)(() => { this.props.advancePage(isSkip)})}
                        onTouchStart={interactionHandler.bind(this)(() => this.props.advancePage(isSkip))} alt="">
                    <span className='button-txt'>{buttonText}</span>
                    <img src={this.props.baseTheme === "Light" ? rightArrowBlack : rightArrowWhite} alt=""/>
                </button>
            );
        }
    }

    _getBackButton() {
        if (this.props.showBackBtn) {
            const buttonText = translate(this.props.backText, this.props.lang);

            // NOTE: No alt text for the image when there already is a button text. But there should be an empty alt attribut on the button
            return (
                <button id="prev-page" onClick={interactionHandler.bind(this)(this.props.goBackPage)}
                        onTouchStart={interactionHandler.bind(this)(this.props.goBackPage)} alt="">
                    <img src={this.props.baseTheme === "Light" ? leftArrowBlack : leftArrowWhite} alt=""/>
                    <span className='button-txt'>{buttonText}</span>
                </button>
            );
        }
    }

    _getInfoButton() {
        if (this.props.showInfoBtn) {
            return <button id="show-info"
                           aria-label="Information"
                           onClick={interactionHandler.bind(this)(this.props.toggleQuestionInfo)}
                           onTouchStart={interactionHandler.bind(this)(this.props.toggleQuestionInfo)} />;
        }
    }

    _hideForwardBtn() {
        return this.props.questionPrompt;
    }

    _canShowForwardBtn() {
        const { currentQuestion } = this.props;
        let questionData = this._getQuestionData(currentQuestion.id);

        if (this.props.questionPrompt) {
            return {canProceed: false, isSkip: false};
        }

        if (questionData && !isFactors(currentQuestion)) {
            return {canProceed: true, isSkip: false};
        }

        if (isFactors(currentQuestion) && questionData
            && questionData.filter((o) => o.intValue !== SKP_INTVAL).length === currentQuestion.options.length) {
            return {canProceed: true, isSkip: false};
        }

        if (currentQuestion.notRequired || currentQuestion.showSKP) {
            return {canProceed: true, isSkip: true};
        }

        return {canProceed: false, isSkip: false};
    }

    _getQuestionData(qId) {
        const qData = this.state.surveyData[qId];

        if (!qData) {
            return;
        }
        if (!Array.isArray(qData)) {
            if (Array.isArray(qData.optionId) ? qData.optionId[0] === SKP_ID : qData.optionId === SKP_ID) {
                return;
            }
            if (Array.isArray(qData.optionId) && qData.optionId.length === 0) {
                return;
            }
            if (!(qData.intValue || qData.optionId || qData.metaData)) {
                return;
            }
        }

        return qData;
    }
}
