import React, {Component} from "react";

import rightArrow from "./right.svg";
import { getHeader, interactionHandler, lengthClass, lineMaxLength, translate } from "./helpers";
import { getQuestionSavedProgress } from "./utils";
import {CheckboxBar, CheckboxImage, NpsElement, RadioBar, RadioImage} from "./QuestionElements";
import { DK_ID, DK_INTVAL, SKP_ID, SKP_INTVAL } from './constants';



class Question extends Component {
    constructor(props) {
        super(props);
        if (!props) {
            console.log(props);
        }
        if (!props.question) {
            console.log(props);
        }
        if (props.question && props.question.options) {
            // this.options = props.question.options.filter((o) => o.intValue !== -1);
            this.options = props.question.options.filter((o) => o.intValue >= 0);
            // this.options.sort((o1, o2) => o1.intValue === -2 ? 1 : (o2.intValue === -2 ? -1 : 0));
            this.dkOption = props.question.options.find((o) => o.intValue === DK_INTVAL);
        }
    }

    _setDkValue() {
        throw new Error('Needs override');
    }
    _getDkButton() {
        if (!this.dkOption) {
            return null;
        }
        return (
            <FloatingDKButton setValue={this._setDkValue.bind(this)} isSelected={this._isSelected({ id: DK_ID })}>
                {translate(this.props.dkText, this.props.lang)}
            </FloatingDKButton>
        );
    }
}

class SingleQuestion extends Question {
    constructor(props) {
        super(props);
        const savedProgress = getQuestionSavedProgress(props.question.id);
        this.state = {selectedValue: savedProgress && savedProgress.optionId !== SKP_ID ? savedProgress.optionId : null};
    }
    reset() {
        this.setState({selectedValue: null})
    }
    _setQuestionValue(val, blockAdvance) {
        this.setState({selectedValue: val.value});

        this.props.setSurveyData({
            type: this.props.question.type ? this.props.question.type.family : null,
            action: 'setQuestion',
            dataPackage: {
                questionId: this.props.question.id,
                optionId: !!val.value ? val.value : null,
                intValue: (!!val.intValue || val.intValue === 0) ? parseInt(val.intValue, 10) : null,
                metaData: !!val.metaData ? val.metaData : null
            }
        });

        if (!blockAdvance) {
            this._advancePageIfCheck();
        }
    }
    _setDkValue() {
        this._setQuestionValue({ value: DK_ID, intValue: DK_INTVAL });
    }

    _advancePageIfCheck() {
        this.props.advancePage()
    }

    _isSelected(option) {
        return this.state.selectedValue === option.id;
    }
}

class MultipleQuestion extends Question {
    constructor(props) {
        super(props);
        const savedProgress = getQuestionSavedProgress(props.question.id);
        if (savedProgress && savedProgress.optionId && !Array.isArray(savedProgress.optionId)) {
            console.log('Question with wrong progress', props.question);
            console.log('savedProgress.optionId', savedProgress.optionId);
            console.log('props', props);
        }
        this.state = {
            selectedValue: savedProgress && savedProgress.optionId !== SKP_ID && savedProgress.optionId[0] !== SKP_ID
                ? (!Array.isArray(savedProgress.optionId) ? [savedProgress.optionId] : savedProgress.optionId)
                : []
        };
    }
    reset() {
        this.setState({selectedValue: []})
    }

    _setElemValue(val, blockAdvance) {
        let currentOptions = this.state.selectedValue.slice();

        if (currentOptions.indexOf(val.value) > -1) {
            return;
        }
        if (val.value === DK_ID) {
            currentOptions = [DK_ID];
        } else {
            currentOptions.push(val.value);
            currentOptions = currentOptions.filter((o) => o !== DK_ID);
        }
        this.setState({selectedValue: currentOptions});

        this.props.setSurveyData({
            type: this.props.question.type ? this.props.question.type.family : null,
            action: 'setQuestion',
            dataPackage: {
                questionId: this.props.question.id,
                optionId: currentOptions,
                intValue: val.intValue !== undefined ? parseInt(val.intValue, 10) : null,
                metaData: null,
            }
        });
        if (!blockAdvance) {
            this._advancePageIfCheck(currentOptions);
        }
    }

    _setDkValue() {
        this._setElemValue({ value: DK_ID, intValue: DK_INTVAL });
    }

    _unsetElementValue(val) {
        let currentOptions = this.state.selectedValue.slice(),
            removedIndex = currentOptions.indexOf(val.value);

        if (removedIndex === -1) {
            return;
        }

        currentOptions.splice(removedIndex, 1);
        this.setState({selectedValue: currentOptions});
        this.props.setSurveyData({
            type: this.props.question.type ? this.props.question.type.family : null,
            action: 'setQuestion',
            dataPackage: {
                questionId: this.props.question.id,
                optionId: currentOptions,
                intValue: val.intValue !== undefined ? parseInt(val.intValue, 10) : null,
                metaData: null,
            }
        });
    }

    _advancePageIfCheck(currentOptions) {
        if (currentOptions.length === this._getMaxAnswers() || currentOptions.includes(DK_ID))
            this.props.advancePage();
    }

    _isDisabled(option) {
        return this.state.selectedValue.length >= this._getMaxAnswers()
            && this.state.selectedValue.indexOf(option.id) === -1;
    }

    _isSelected(option) {
        return this.state.selectedValue.indexOf(option.id) > -1;
    }

    _maxChoicesNotification() {
        if (this.props.question.maxAnswersMsg) {
            return <h5 className="deselect-first-alert">{translate(this.props.question.maxAnswersMsg)}</h5>;
        }
    }

    _getMaxAnswers() {
        return this.props.question.maxAnswers || this.options.length;
    }
}

export class CheckboxBars extends MultipleQuestion {
    render() {
        const chekboxes = this._getCheckboxes() || [];

        return (
            <div className={`question cb-bars ${this.props.subType || ''}`}>
                <fieldset className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    {this._maxChoicesNotification()}
                    {chekboxes}
                </fieldset>
                {this._getDkButton()}
            </div>
        );
    }

    _getCheckboxes() {
        let extraClass = !this.state.selectedValue ? 'not-selected' : '';
        return this.options.map(
            (option) => <CheckboxBar name={this.props.question.id} option={option} extraClass={extraClass}
                                     lang={this.props.lang} key={option.id}
                                     setValue={this._setElemValue.bind(this)}
                                     unsetValue={this._unsetElementValue.bind(this)}
                                     checked={this._isSelected(option)}
                                     disabled={this._isDisabled(option)}/>
        );
    }
}

export class RadioBars extends SingleQuestion {
    render() {
        const options = this._getOptions() || [];
        return (
            <div className={`question radio-bars ${this.props.subType || ''}`}>
                <fieldset className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    {options}
                </fieldset>
            </div>
        );
    }

    _getOptions() {
        let extraClass = !this.state.selectedValue ? 'not-selected' : '';
        let { question: { options }, subType } = this.props;
        const optionValues = options.filter(o => o.intValue >= 0);
        const dkOption = options.find(o => o.intValue === -2);

        if (optionValues && subType === 'rating') {
            optionValues.sort((opt1, opt2) => opt2.intValue - opt1.intValue);
        }
        if (dkOption) {
            this.dkOption.caption = this.props.dkText;
            if (subType === 'rating') {
                dkOption.cssClass = "rating-dk";
            }
            optionValues.push(dkOption);
        }

        return optionValues.map(
            (option) => <RadioBar name={this.props.question.id} option={option} key={option.id} extraClass={extraClass}
                                  lang={this.props.lang} setValue={this._setQuestionValue.bind(this)}
                                  checked={this._isSelected(option)} isDark={this.props.isDark} />
        );
    }
}

export class Smileys extends SingleQuestion {
    render() {
        const smileys = this._getSmileys() || [];
        if (this.dkOption) {
            this.dkOption.caption = this.props.dkText;
        }

        return (
            <div className={`question smileys smileys-${this.options.length}`}>
                <fieldset className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    <div className="options-wrap">
                        {smileys}
                        {this.options.length < 10 && this.dkOption ? this._getSmiley(this.dkOption) : null}
                    </div>
                    {this.dkOption && this.options.length >= 10 && 
                        <div className="dkOption-10">
                            {this._getSmiley(this.dkOption)}
                        </div>
                    }
                </fieldset>
            </div>
        );
    }

    _getSmileys() {
        return this.options.map(
            (option) => this._getSmiley(option));
    }
    _getSmiley(option) {
        let extraClass = !this.state.selectedValue ? 'not-selected' : '';
        return <RadioImage name={this.props.question.id} option={option} key={option.id} lang={this.props.lang}
                                    setValue={this._setQuestionValue.bind(this)}  extraClass={extraClass}
                                    checked={this._isSelected(option)} isDark={this.props.isDark} />
    }
}

export class Nps extends SingleQuestion {
    render() {
        return (
            <fieldset className="question nps">
                {getHeader(this.props.question, this.props.lang, "container")}
                <div className="grey-background">
                    <div className="container">
                        <div className="options-wrap">
                            {this._getNpsElements() || []}
                        </div>
                    </div>
                </div>
                {this._getDkButton()}
            </fieldset>
        );
    }

    _getNpsElements() {
        return this.options.map(
            (option, i) => <NpsElement name={this.props.question.id} i={i} key={i} option={option}
                                       isFirst={i === 0} isLast={i === this.options.length - 1}
                                       lang={this.props.lang} setValue={this._setQuestionValue.bind(this)}
                                       checked={this._isSelected(option)}/>
        );
    }
}

export class RadioImages extends SingleQuestion {
    render() {
        const images = this._getImages() || [];
        if (this.dkOption) {
            this.dkOption.caption = this.props.dkText;
        }
        const dkOptionImage = this.dkOption ? this._getImage(this.dkOption) : null;
        return (
            <div className="question images">
                <fieldset className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    <div className={"options-wrap line-wrap line-wrap-size-" + lineMaxLength(images.length)}>
                        {images}
                        {dkOptionImage}
                    </div>
                </fieldset>
            </div>
        );
    }

    _getImages() {
        return this.options.map(
            (option) => this._getImage(option));
    }
    _getImage(option) {
        let extraClass = !this.state.selectedValue ? 'not-selected' : '';
        return <RadioImage name={this.props.question.id} option={option} key={option.id} lang={this.props.lang}
                                    setValue={this._setQuestionValue.bind(this)} extraClass={extraClass}
                                    checked={this._isSelected(option)} isDark={this.props.isDark} />
    }
}

export class CheckboxImages extends MultipleQuestion {
    render() {
        const images = this._getImages() || [];

        return (
            <div className="question images">
                <fieldset className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    <div className={"options-wrap line-wrap line-wrap-size-" + lineMaxLength(images.length)}>
                        {this._maxChoicesNotification()}
                        {images}
                    </div>
                </fieldset>
                {this._getDkButton()}
            </div>
        );
    }

    _getImages() {
        let extraClass = !this.state.selectedValue.length ? 'not-selected' : '';
        return this.options.map(
            (option) => <CheckboxImage name={this.props.question.id} option={option} extraClass={extraClass}
                                       lang={this.props.lang} key={option.id}
                                       setValue={this._setElemValue.bind(this)}
                                       unsetValue={this._unsetElementValue.bind(this)}
                                       checked={this._isSelected(option)}
                                       disabled={this._isDisabled(option)}/>)
    }
}

export class Factors extends Component {
    constructor(props) {
        super(props);
        const factorValues = {};
        const savedProgress = getQuestionSavedProgress(props.question.id);
        if (savedProgress) {
            savedProgress.forEach((o) => {
                if (o.intValue !== SKP_INTVAL) {
                    factorValues[o.optionId] = o.intValue;
                }
            })
        }
        this.state = {factorValues};

        this.options = props.question.properties.filter((o) => o.intValue !== -1);
        const dkOption = this.options.find(o => o.intValue === -2);
        if (dkOption) {
            dkOption.caption = this.props.dkText;
        }
        this.options.sort((o1, o2) => o1.intValue === -2 ? 1 : (o2.intValue === -2 ? -1 : 0));
    }
    reset() {
        this.setState({factorValues: {}})
    }

    render() {
        return (
            <div className="question factors">
                <fieldset>
                    {getHeader(this.props.question, this.props.lang, "container")}
                    {this.options.length < 10 &&
                        <div className="header">
                            <div className="left-col-header">&nbsp;</div>
                            <div className="right-col-header">
                                {this.options.map((o) => (
                                    <div key={o.id} className="f1"><h3>{translate(o.caption, this.props.lang)}</h3></div>
                                ))}
                            </div>
                        </div>
                    }
                    <div>
                        {this._getFactors()}
                    </div>
                </fieldset>
            </div>
        );
    }

    _getFactors() {
        let qData = this.state.factorValues;
        const captions = this.props.question.options.map(factor => translate(factor.caption, this.props.lang));
        captions.sort((c1, c2) => c2.length-c1.length);

        return this.props.question.options.map(
            (factor) => <Factor question={factor} options={this.options} key={factor.id}
                                lengthCls={lengthClass(captions[0])}
                                lang={this.props.lang} setFactorData={this._setFactorData.bind(this)}
                                advancePageIfCheck={this._advancePageIfCheck.bind(this)}
                                questionData={typeof qData[factor.id] !== 'undefined' ? qData[factor.id] : null} isDark={this.props.isDark}/>
        )
    }

    _setFactorData(fieldData, blockAdvance) {
        let factorValues = {...this.state.factorValues};

        factorValues[fieldData.value] = fieldData.intValue;
        this.setState({factorValues});
        this.props.setSurveyData({
            type: 'factor',
            action: 'setOption',
            dataPackage: {
                questionId: this.props.question.id,
                optionId: fieldData.value,
                intValue: fieldData.intValue,
                metaData: null
            }
        });

        if (!blockAdvance) {
            this._advancePageIfCheck(factorValues);
        }
    }

    _advancePageIfCheck(currentValues) {
        const factors = this.props.question.options;

        for (let i = 0; i < factors.length; i++) {
            if (typeof currentValues[factors[i].id] === 'undefined') {
                return;
            }
        }
        this.props.advancePage();
    }
}

class Factor extends Component {
    render() {
        let caption = translate(this.props.question.caption, this.props.lang);
        if (this.props.options.length === 11) { // TODO: For now, assume 10-scale with dkOption
            var smileys = this._getSmileys();
            var dkOption = smileys[10];
            var dkCaption = dkOption.caption ? translate(dkOption.caption, this.props.lang) : "";
            smileys = smileys.slice(0, 10);
            return (
                <div className="dkOption-10 factor">
                    <div className={"question factor-smileys smileys smileys-10"}>
                        <fieldset className="container">
                            <legend className="left-col">
                                <h3 className={`factor-caption ${this.props.lengthCls || lengthClass(caption)}`}>{caption}</h3>
                            </legend>
                            <div className="dkHeader">
                                <div className="header-space">
                                    {smileys.map(s => <div className="spacer">&nbsp;</div>)}
                                </div>
                                <div className="dk-caption">
                                    {dkCaption}
                                </div>
                            </div>
                            <div className="right-col">
                                <div className="options-wrap">
                                    {smileys}
                                </div>
                                <div className="option-container">
                                    <div className="dk-caption">{dkCaption}</div>
                                    <div className="dkOption">
                                        {dkOption}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            );
        }

        return (
            <div className="factor">
                <fieldset className={"question factor-smileys smileys smileys-" + this.props.options.length}>
                    <legend className="left-col">
                        <h3 className={this.props.lengthCls || lengthClass(caption)}>{caption}</h3>
                    </legend>
                    <div className="options-wrap right-col">
                        {this._getSmileys()}
                    </div>
                </fieldset>
            </div>
        );
    }

    _getSmileys() {
        let extraClass = this.props.questionData === null ? 'not-selected' : '';
        return this.props.options.map((option) =>
            <RadioImage name={this.props.question.id} questionType="factors" option={{...option, id: this.props.question.id}}
                        key={option.id} setValue={this.props.setFactorData} extraClass={extraClass}
                        checked={this._isSelected(option)} isDark={this.props.isDark} />);
    }

    _isSelected(option) {
        return this.props.questionData && this.props.questionData === option.intValue;
    }

    _advancePageIfCheck() {
        this.props.advancePageIfCheck()
    }
}

class TextGeneric extends SingleQuestion {
    constructor(props) {
        super(props);
        this.reset(true);
    }
    reset(isInit) {
        const savedProgress = getQuestionSavedProgress(this.props.question.id);
        this.completionDeclared = false;
        if (this.textInput && this.textInput.value) {
            this.textInput.value = null;
        }
        const initialState = {selectedValue: savedProgress && savedProgress.metaData ? {metaData: savedProgress.metaData} : null};
        if (!isInit) this.setState(initialState);
        else this.state = initialState;
    }
    componentDidMount() {
        if (this.textInput) {
            console.log('this.textInput', this.textInput);
            const savedProgress = getQuestionSavedProgress(this.props.question.id);
            if (savedProgress && savedProgress.metaData) this.textInput.value = savedProgress.metaData;
        }
    }

    _setValueFromEvent(e) {
        this.props.resetTimer();
        if (!this.completionDeclared || !e.target.value) {
            this.completionDeclared = !!e.target.value;
            this._sendValue(true);
        }
    }

    _sendValue(stayIn) {
        if (!stayIn) this.textInput.blur();
        this._setQuestionValue({metaData: this.textInput.value});
    }

    _advancePageIfCheck() {

    }

    _leaveFocus() {
        if (window.webAppDevType && (window.webAppDevType === 'android' || window.webAppDevType === 'ipad')) {
            document.body.className = document.body.className.replace('keyboard-on', '').trim();
            this.props.setFooterVisibility(true);
        }
        this.textInput.placeholder = translate(this.props.placeholder, this.props.lang);
    }
    _setFocus() {
        if (window.webAppDevType && (window.webAppDevType === 'android'
                || (window.webAppDevType === 'ipad' && window.screen.height > window.innerHeight))) {
            if (document.body.className.indexOf('keyboard-on') === -1) document.body.className += ' keyboard-on';
            this.props.setFooterVisibility(false);
        }
        this.textInput.placeholder = '';
    }
    _getSendButton() {
        if (this.textInput) {
            const sendText = this.props.proceedText && translate(this.props.proceedText, this.props.lang);
            return <div className="continue-button-wrap">
                <button onClick={interactionHandler.bind(this)(this.props.advancePage)}
                        onTouchStart={interactionHandler.bind(this)(this.props.advancePage)}
                        className="start-survey yes">
                    {sendText}
                    <img className="fwdArrow non-animated" src={rightArrow} alt="ForwardButton"/>
                </button>
            </div>
        }
    }
}
export class Comments extends TextGeneric {
    render() {
        return (
            <div className="question text comments">
                <div className={"overlay-glass" + (!this.props.putOverlay ? ' hidden' : '')}> </div>
                <fieldset className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    <div className="text-input-wrapper">
                        <textarea onChange={this._setValueFromEvent.bind(this)}
                                  onFocus={this._setFocus.bind(this)}
                                  onBlur={this._leaveFocus.bind(this)}
                                  placeholder={translate(this.props.placeholder, this.props.lang)}
                                  ref={(textInput) => this.textInput = textInput} />
                        {this._getSendButton()}
                    </div>
                </fieldset>
            </div>
        );
    }
}

export class TextLine extends TextGeneric {
    render() {
        return (
            <div className="question text text-line">
                <div className={"overlay-glass" + (!this.props.putOverlay ? ' hidden' : '')}> </div>
                <fieldset className="container">
                    {getHeader(this.props.question, this.props.lang)}
                    <div className="text-input-wrapper">
                        <input type="text" inputMode={{number: 'numeric', textline: 'text'}[this.props.question.type.tag]}
                               className={this.state.size}
                               onChange={this._setValueFromEvent.bind(this)}
                               onFocus={this._setFocus.bind(this)}
                               onBlur={this._leaveFocus.bind(this)}
                               ref={(textInput) => this.textInput = textInput}
                               placeholder={translate(this.props.placeholder, this.props.lang)}
                               onKeyPress={(e) => e.which === 13 && this.props.advancePage()}/>
                        {this._getSendButton()}
                    </div>
                </fieldset>
            </div>
        );
    }
}

class FloatingDKButton extends Component {
    render() {
        let { setValue, isSelected, children } = this.props;
        const interact = interactionHandler.bind(this)(setValue);
        return (
            <div className="floating-dk-wrapper">
                <button className={'start-survey floating-dk' + (isSelected ? ' selected' : '')}
                        onClick={interact} onTouchStart={interact} type="button"
                        alt={children}
                >
                    <img src={process.env.PUBLIC_URL + '/Button-X-grey.png'} alt={children}/>
                    {children}
                </button>
            </div>
        );
    }
}
